import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";

export interface IUser {
    _id: string;
    name: string;
    email: string;
    role: "Reviewer" | "Editor" | "user" | "guest";
    profileImage: string | null;
}

export interface IState {
    value: string;
    auth: string;
    user: IUser;
}

@Injectable({
    providedIn: "root",
})
export class AuthService {
    state: IState = {
        value: "loggedOut",
        auth: "",
        user: {
            _id: "",
            name: "",
            email: "",
            role: "guest",
            profileImage: null,
        },
    };

    private _state$ = new BehaviorSubject(this.state);

    public get state$() {
        return this._state$.asObservable();
    }

    constructor(private http: HttpService, private route: ActivatedRoute) {}

    getUser() {
        this.http.getUserDetails().subscribe(data => {
            const { user } = data;
            if (user) {
                this.state.value = "loggedIn";
                this.state.user = user;
                this.state.auth = "USER";
            }
            this.setUser(this.state.user, this.state.auth);
        });
    }

    setUser(userData, auth) {
        const currentState = this._state$.value;
        currentState.user = userData;
        currentState.auth = auth;
        this._state$.next(currentState);
    }
}
