<div class="dialog-lc" [ngClass]="{ 'dialog-lc-mobile': isMobile() }">
    <div class="dialog-lc-title">
        {{
            updateDetails
                ? "Want to update your details?"
                : "Want to Leave a Comment?"
        }}
    </div>
    <div *ngIf="!isGuest" class="dialog-lc-description">
        Create a CREE8 account to post your comment
    </div>
    <div *ngIf="isGuest" class="dialog-lc-description">
        Please enter your details:
    </div>

    <!-- Form itself -->
    <div class="dialog-lc-form" [formGroup]="form">
        <div class="dialog-lc-field">
            <label class="custom-label" for="dialog-name">Full Name</label>
            <input
                formControlName="name"
                class="custom-input"
                id="dialog-name"
                type="text"
                placeholder="Enter your name"
                [ngClass]="{
                    'custom-input-error':
                        form.controls.name.errors && form.controls.name.touched
                }"
            />
            <div
                class="custom-input-error-text"
                *ngIf="form.controls.name.errors && form.controls.name.touched"
            >
                {{ getErrorMessage(form.controls.name) }}
            </div>
        </div>

        <div class="dialog-lc-field">
            <label class="custom-label" for="dialog-email">Email</label>
            <input
                formControlName="email"
                class="custom-input"
                id="dialog-email"
                type="text"
                placeholder="alma.lawson@example.com"
                [ngClass]="{
                    'custom-input-error':
                        form.controls.email.errors &&
                        form.controls.email.touched
                }"
            />
            <div
                class="custom-input-error-text"
                *ngIf="
                    form.controls.email.errors && form.controls.email.touched
                "
            >
                {{ getErrorMessage(form.controls.email) }}
            </div>
        </div>

        <ng-container *ngIf="!isGuest">
            <div class="dialog-lc-field">
                <label class="custom-label" for="dialog-password"
                    >Password</label
                >
                <input
                    formControlName="password"
                    class="custom-input"
                    id="dialog-password"
                    placeholder="5+ characters"
                    type="password"
                    [ngClass]="{
                        'custom-input-error':
                            form.controls.password.errors &&
                            form.controls.password.touched
                    }"
                />
                <div
                    class="custom-input-error-text"
                    *ngIf="
                        form.controls.password.errors &&
                        form.controls.password.touched
                    "
                >
                    {{ getErrorMessage(form.controls.password) }}
                </div>
            </div>

            <div class="dialog-lc-bottom">
                By clicking “Create Account” I agree to BeBop
                <a href="" target="_blank" rel="noopener noreferrer">Terms</a>
                and
                <a href="" target="_blank" rel="noopener noreferrer"
                    >Privacy Policy</a
                >.
            </div>

            <div class="dialog-lc-bottom">
                Already have an account?
                <a href="" target="_blank" rel="noopener noreferrer">Log in</a>
            </div>
        </ng-container>

        <div class="dialog-lc-buttons">
            <button
                class="dialog-lc-buttons-button custom-button custom-button-stroked"
                (click)="onClose($event)"
            >
                Cancel
            </button>
            <button
                class="dialog-lc-buttons-button custom-button custom-button-filled"
                (click)="onClose($event, true)"
                [ngClass]="{ inactive: form.status === 'INVALID' }"
                [disabled]="form.status === 'INVALID'"
            >
                {{
                    isGuest
                        ? updateDetails
                            ? "UPDATE"
                            : "Leave Comment"
                        : "CREATE ACCOUNT"
                }}
            </button>
        </div>
    </div>
</div>
