import { Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";

@Injectable({
    providedIn: "root",
})
export class DataService {
    constructor(private sanitizer: DomSanitizer) {}
    private appLogo = "assets/images/bbp-logo.png";
    hasCustomLogo = false;
    linkDetails = null;

    setAppLogo(data) {
        if (!data) return;
        this.appLogo = "data:image/png;base64, " + data;
        this.hasCustomLogo = true;
    }

    getAppLogo(): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(this.appLogo);
    }

    setLinkDetails(linkDetails) {
        this.linkDetails = linkDetails;
    }

    getLinkDetails() {
        return this.linkDetails;
    }
}
