import {
    Injectable,
    ComponentFactoryResolver,
    ApplicationRef,
    Injector,
    EmbeddedViewRef,
    ComponentRef,
    Type,
} from "@angular/core";
import { CustomDialogModule } from "./custom-dialog.module";
import { CustomDialogConfig } from "./custom-dialog-config";
import { CustomDialogRef } from "./custom-dialog-ref";
import { CustomDialogInjector } from "./custom-dialog-injector";
import { CustomDialogComponent } from "./custom-dialog.component";

@Injectable({
    providedIn: CustomDialogModule,
})
export class CustomDialogService {
    dialogComponentRef: ComponentRef<CustomDialogComponent>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {}

    public open(componentType: Type<any>, config: CustomDialogConfig) {
        const dialogRef = this.appendDialogToBody(config);

        this.dialogComponentRef.instance.childComponentType = componentType;

        return dialogRef;
    }

    public close() {
        this.removeDialogFromBody();
    }

    private appendDialogToBody(config) {
        const map = new WeakMap();
        map.set(CustomDialogConfig, config);

        const dialogRef = new CustomDialogRef();
        map.set(CustomDialogRef, dialogRef);

        const sub = dialogRef.afterClosed.subscribe(() => {
            this.removeDialogFromBody();
            sub.unsubscribe();
        });

        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            CustomDialogComponent
        );
        const componentRef = componentFactory.create(
            new CustomDialogInjector(this.injector, map)
        );

        this.appRef.attachView(componentRef.hostView);
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;
        document.body.prepend(domElem);

        this.dialogComponentRef = componentRef;

        this.dialogComponentRef.instance.onClose.subscribe(() => {
            this.removeDialogFromBody();
        });

        return dialogRef;
    }

    private removeDialogFromBody(): void {
        this.appRef.detachView(this.dialogComponentRef.hostView);
        this.dialogComponentRef.destroy();
    }
}
