<div class="layout">
    <div class="layout-wrapper">
        <a href="https://cree8.io" target="_blank">
            <img [src]="dataService.getAppLogo()" class="layout-card-logo"
        /></a>
        <div class="layout-card">
            <router-outlet></router-outlet>
        </div>
        <div class="powered-by">
            <a href="https://cree8.io" target="_blank">
                <img src="assets/images/powered-by-bebop.png"
            /></a>
        </div>
        <a
            class="support"
            href="https://support.cree8.io/"
            target="_blank"
        >
            <img src="assets/images/support-icon.svg" alt="" />
        </a>
    </div>
</div>
