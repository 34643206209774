import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Output,
} from "@angular/core";

@Directive({
    selector: "[bebopClickOutside]",
})
export class ClickOutsideDirective {
    constructor(private _elementRef: ElementRef) {}

    @Output()
    public bebopClickOutside = new EventEmitter<MouseEvent>();

    @HostListener("document:mousedown", ["$event", "$event.target"])
    public onClick(event: MouseEvent, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        const clickedInside = this._elementRef.nativeElement.contains(
            targetElement
        );
        if (!clickedInside) {
            this.bebopClickOutside.emit(event);
        }
    }
}
