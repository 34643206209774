import { IUser } from "src/app/services/auth.service";

export const LSHandler = (valueName, data = null): any => {
    if (data) {
        const userObj: IUser = {
            _id: data.name.toLowerCase() + "_" + Date.now(),
            name: data.name,
            role: "guest",
            email: data.email,
            profileImage: "",
        };
        localStorage.setItem("userData", JSON.stringify(userObj));
        return userObj;
    }

    let value = JSON.parse(localStorage.getItem(valueName)) || {
        name: "__guest__",
    };

    value.role = "guest"; // For existing local storage values with role as user
    return value;
};
