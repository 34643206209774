<div
    class="toolbar-user-wrapper"
    *ngIf="user"
    (click)="toggleDropdown()"
    (bebopClickOutside)="onClickOutside()"
>
    <div class="toolbar-user">
        <img
            class="toolbar-user-avatar"
            [src]="user.profileImage || 'assets/images/avatars/noavatar.png'"
        />
        <div class="toolbar-user-details">
            <div
                class="toolbar-user-details-name"
                [ngClass]="{ 'toolbar-user-details-name-mobile': isMobile() }"
            >
                {{ user.name }}
            </div>
            <div class="toolbar-user-details-role" *ngIf="user.role">
                {{ user.role }}
            </div>
        </div>
        <i
            [class]="
                isUserOpen
                    ? 'icon-arrow-up-keyboard'
                    : 'icon-arrow-down-keyboard'
            "
        ></i>
    </div>

    <div class="toolbar-user-dropdown" [class.open]="isUserOpen">
        <div class="toolbar-user-dropdown-list">
            <div
                class="toolbar-user-dropdown-list-item"
                tabindex="0"
                (click)="updateDisplayName()"
                *ngIf="user.role == 'guest'"
            >
                <img src="assets/images/profile.svg" />
                Update Guest Profile
            </div>
            <div
                class="toolbar-user-dropdown-list-item"
                tabindex="0"
                (click)="onLogin()"
                *ngIf="user.role == 'guest'"
            >
                <img src="assets/images/login.svg" />
                Login via CREE8
            </div>
            <div
                class="toolbar-user-dropdown-list-item"
                tabindex="0"
                (click)="onLogout()"
                *ngIf="user.role != 'guest'"
            >
                <img src="assets/images/logout.svg" />
                Logout
            </div>
        </div>
    </div>
</div>
