import { Component, OnInit, Input } from "@angular/core";
import { Notification } from "./custom-notification/custom-notification.component";
import { CustomNotificationsService } from "./custom-notifications.service";
import { Observable } from "rxjs";

@Component({
    selector: "app-custom-notifications",
    templateUrl: "./custom-notifications.component.html",
    styleUrls: ["./custom-notifications.component.scss"],
})
export class CustomNotificationsComponent implements OnInit {
    @Input() position: string = "bottom-left";
    constructor(private notifications: CustomNotificationsService) {}

    public notificationList: Observable<Notification[]>;

    ngOnInit() {
        this.notificationList = this.notifications.get();
    }
}
