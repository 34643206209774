import {
    Component,
    OnInit,
    Type,
    OnDestroy,
    AfterViewInit,
    ComponentRef,
    ViewChild,
    ComponentFactoryResolver,
    ChangeDetectorRef,
} from "@angular/core";
import { Subject } from "rxjs";
import { CustomDialogDirective } from "./custom-dialog.directive";
import { CustomDialogRef } from "./custom-dialog-ref";
import { fadeAnimationVoid } from "../../animation";
import { CustomDialogConfig } from "./custom-dialog-config";

@Component({
    selector: "app-custom-dialog",
    templateUrl: "./custom-dialog.component.html",
    styleUrls: ["./custom-dialog.component.scss"],
    animations: [fadeAnimationVoid],
})
export class CustomDialogComponent implements OnInit, AfterViewInit, OnDestroy {
    componentRef: ComponentRef<any>;
    childComponentType: Type<any>;

    @ViewChild(CustomDialogDirective, { static: false })
    insertionPoint: CustomDialogDirective;

    private readonly _onClose = new Subject<any>();
    public onClose = this._onClose.asObservable();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cd: ChangeDetectorRef,
        public dialogRef: CustomDialogRef,
        private dialogConfig: CustomDialogConfig
    ) {}

    onOverlayClicked(evt: MouseEvent): void {
        this.dialogRef.close();
    }

    onDialogClicked(evt: MouseEvent): void {
        evt.stopPropagation();
    }

    loadChildComponent(componentType: Type<any>) {
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            componentType
        );

        let viewContainerRef = this.insertionPoint.viewContainerRef;
        viewContainerRef.clear();

        this.componentRef = viewContainerRef.createComponent(componentFactory);
    }

    ngOnInit() {}

    ngAfterViewInit() {
        this.loadChildComponent(this.childComponentType);
        this.cd.detectChanges();
    }

    ngOnDestroy() {
        if (this.componentRef) {
            this.componentRef.destroy();
        }
    }

    close() {
        this._onClose.next();
    }
}
