<div class="layout">
    <div class="layout-toolbar">
        <app-toolbar></app-toolbar>
    </div>
    <div class="layout-main">
        <router-outlet></router-outlet>
    </div>
</div>

<app-custom-notifications position="bottom-right"></app-custom-notifications>
