import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Notification } from "./custom-notification/custom-notification.component";

@Injectable({ providedIn: "root" })
export class CustomNotificationsService {
    private maxAmount: number = 10;
    private notificationList = new BehaviorSubject<Notification[]>(
        new Array(this.maxAmount)
    );

    create(notification: Notification): void {
        let newNotificationList = [...this.notificationList.value];
        let index = newNotificationList.findIndex(value => !value);
        if (index === -1) {
            index = 0;
        }
        notification.id = index;
        newNotificationList[index] = notification;
        this.notificationList.next(newNotificationList);
        setTimeout(
            () => {
                this.destroy(notification);
            },
            notification.timeout ? notification.timeout : 3000
        );
    }

    destroy(notification: Notification): void {
        let newNotificationList = [...this.notificationList.value];
        newNotificationList[notification.id] = null;
        this.notificationList.next(newNotificationList);
    }

    get() {
        return this.notificationList.asObservable();
    }
}
