import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutComponent } from "./core/layout/layout.component";
import { LayoutProtectedLinksComponent } from "./core/layout/layout-protected-links/layout-protected-links.component";
import { ValidIdService } from "src/app/services/valid-id-service";

const routes: Routes = [
    {
        path: ":id/error",
        pathMatch: "full",
        children: [
            {
                path: "",
                component: LayoutProtectedLinksComponent,
                children: [
                    {
                        path: "",
                        loadChildren: () =>
                            import("src/app/pages/error/error.module").then(
                                m => m.ErrorModule
                            ),
                    },
                ],
            },
        ],
    },
    {
        path: ":id/not-ready",
        pathMatch: "full",
        loadChildren: () =>
            import("src/app/pages/not-ready/not-ready.module").then(
                m => m.NotReadyModule
            ),
    },
    {
        path: ":id/login",
        pathMatch: "full",
        children: [
            {
                path: "",
                component: LayoutProtectedLinksComponent,
                children: [
                    {
                        path: "",
                        loadChildren: () =>
                            import(
                                "src/app/pages/auth/login/login.module"
                            ).then(m => m.LoginModule),
                    },
                ],
            },
        ],
    },
    {
        path: ":id/password",
        pathMatch: "full",
        children: [
            {
                path: "",
                component: LayoutProtectedLinksComponent,
                children: [
                    {
                        path: "",
                        loadChildren: () =>
                            import(
                                "src/app/pages/auth/password/password.module"
                            ).then(m => m.PasswordModule),
                    },
                ],
            },
        ],
    },
    {
        path: ":link",
        children: [
            {
                path: "",
                canActivateChild: [ValidIdService],
                component: LayoutComponent,
                children: [
                    {
                        path: "",
                        loadChildren: () =>
                            import(
                                "src/app/pages/library/video/video.module"
                            ).then(m => m.VideoModule),
                    },
                ],
            },
        ],
    },
    {
        path: "",
        pathMatch: "full",
        loadChildren: () =>
            import("src/app/pages/home/home.module").then(m => m.HomeModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: "enabled",
            anchorScrolling: "enabled",
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
