import { Component, Input, TemplateRef } from "@angular/core";
import { CustomNotificationsService } from "../custom-notifications.service";

export interface Notification {
    title?: string;
    description?: string;
    status?: 0 | 1 | 2 | 3;
    timeout?: number;
    id?: number;
}

@Component({
    selector: "app-custom-notification",
    templateUrl: "./custom-notification.component.html",
    styleUrls: ["./custom-notification.component.scss"],
})
export class CustomNotificationComponent {
    @Input() notification: Notification;
    constructor(private notificationService: CustomNotificationsService) {}

    onClose() {
        this.notificationService.destroy(this.notification);
    }
}
