<div
    class="notification"
    *ngIf="notification"
    [ngClass]="{
        'notification-error': notification.status === 0,
        'notification-warning': notification.status === 1,
        'notification-success': notification.status === 2,
        'notification-info': notification.status === 3
    }"
>
    <div class="notification-top">
        <button class="notification-top-close" (click)="onClose()">
            <i class="notification-top-close-icon icon-close"></i>
        </button>
    </div>
    <div class="notification-middle">
        <!-- <div class="notification-middle-indicator notification-middle-indicator-warning" *ngIf="notification.status === 1; else elseSection">
      !
    </div> -->

        <!-- <ng-template #elseSection> -->

        <i
            warning
            class="notification-middle-indicator"
            [ngClass]="{
                'icon-error': notification.status === 0,
                'icon-warning': notification.status === 1,
                'icon-checked': notification.status === 2,
                'icon-info-filled': notification.status === 3
            }"
        ></i>
        <!-- </ng-template> -->

        <div class="notification-middle-title">{{ notification.title }}</div>
    </div>
    <div class="notification-description">{{ notification.description }}</div>
    <button class="custom-button custom-button-stroked notification-action">
        undo
    </button>
</div>
