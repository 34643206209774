import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, ReplaySubject } from "rxjs";
import { AuthService, IUser } from "src/app/services/auth.service";
import { HttpService } from "src/app/services/http.service";
import { DataService } from "src/app/services/data.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: "root" })
export class VideoService {
    private data = new BehaviorSubject<any>({
        name: "Unknown",
        annotations: [],
    });
    linkId: string;
    baseUrl: string = "";
    userData: IUser;
    private serverUrl: string;
    public txReady: BehaviorSubject<boolean>;
    public streamReady: BehaviorSubject<boolean>;
    private checkingHealth: boolean = false;
    private healthSuccess: boolean = false;
    private healthWatcher: ReturnType<typeof setInterval>;
    private tokenTimer: ReturnType<typeof setInterval>;
    private tokenLastRefreshed;
    private linkDetails;
    private token: string;
    private sessionId: string;
    private _isLeftMainBlockCollapsed$: BehaviorSubject<
        boolean
    > = new BehaviorSubject(false);
    private _frame$: BehaviorSubject<string> = new BehaviorSubject("");
    private _duration$: BehaviorSubject<number> = new BehaviorSubject(0);
    public _playerError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private playOriginalFile = false;

    constructor(
        private httpService: HttpService,
        private httpClient: HttpClient,
        private authService: AuthService,
        private dataService: DataService
    ) {
        this.baseUrl = environment.serverUrl;
        this.authService.state$.subscribe(data => {
            this.userData = data.user;
        });
        this.txReady = new BehaviorSubject<boolean>(false);
        this.streamReady = new BehaviorSubject<boolean>(false);
        this.checkHealth = this.checkHealth.bind(this);
        this.getToken = this.getToken.bind(this);
        this.watchFocus = this.watchFocus.bind(this);
        this.healthCheckTimer = this.healthCheckTimer.bind(this);
        this.startTokenTimer = this.startTokenTimer.bind(this);
        this.stopTokenTimer = this.stopTokenTimer.bind(this);
    }

    public get isLeftMainBlockCollapsed$() {
        return this._isLeftMainBlockCollapsed$.asObservable();
    }

    public get frame$() {
        return this._frame$.asObservable();
    }

    setFrame(value: string) {
        this._frame$.next(value);
    }

    public get duration$() {
        return this._duration$.asObservable();
    }

    setDuration(value: number) {
        this._duration$.next(value);
    }

    setPlayerError(error: boolean) {
        this._playerError$.next(error);
    }

    set(data, isProperty) {
        if (isProperty) {
            this.data.next({ ...this.data.value, ...data });
        } else {
            data.status = data?.status?.replace("_", " ").toLowerCase();
            this.data.next(data);
        }
    }

    setAnnotation(comments) {
        let baseArray = [];
        if (comments.length) {
            comments.forEach(comment => {
                if (comment.annotations.length) {
                    comment.annotations.forEach(annotation => {
                        annotation.actions = annotation.actions.map(action => {
                            return { ...action, locked: true };
                        });
                        baseArray.push(annotation);
                    });
                }
                if (comment.children.length) {
                    comment.children.forEach(reply => {
                        if (reply.annotations.length) {
                            reply.annotations.forEach(annotation => {
                                annotation.actions = annotation.actions.map(
                                    action => {
                                        return { ...action, locked: true };
                                    }
                                );
                                baseArray.push(annotation);
                            });
                        }
                    });
                }
            });
        }

        this.data.next({
            ...this.data.value,
            annotations: baseArray,
        });
    }

    deleteAnnotation(comment) {
        const baseArray = this.data.value.annotations;
        baseArray.forEach(item => {
            comment.annotations.forEach(elem => {
                if (elem._id === item._id) {
                    var index = baseArray.indexOf(item);
                    baseArray.splice(index, 1);
                }
            });
        });

        this.data.next({
            ...this.data.value,
            annotations: baseArray,
        });
    }

    get(): Observable<any> {
        return this.data.asObservable();
    }

    getTime() {
        return this.data.value.player;
    }

    goToTime(value) {
        this.data.value.player.currentTime(value);
        this.data.value.player.pause();
    }

    serviceRefresh() {
        // here we will be receiving data from backend
    }

    async updateLinkStatus(status: string, linkId: string) {
        let params = new HttpParams();
        params = params.append(`sessionId`, linkId);
        const updateLinkStatusRes = await this.httpService
            .updateLinkStatus(status, linkId)
            .toPromise();
        if (updateLinkStatusRes?.success === true) {
            status = status.replace("_", " ").toLowerCase();
            this.set({ status }, true);
            this.httpClient
                .get(`${this.baseUrl}/api/v1/videos/update-status`, {
                    params,
                })
                .toPromise()
                .then(res => console.log("videos/update-status res:", res));
        }
        return updateLinkStatusRes;
    }

    sleep(seconds) {
        return new Promise(resolve => {
            setTimeout(resolve, seconds * 1000);
        });
    }

    iOS(): boolean {
        let platform =
            navigator?.userAgentData?.platform || navigator?.platform;
        return (
            [
                "iPad Simulator",
                "iPhone Simulator",
                "iPod Simulator",
                "iPad",
                "iPhone",
                "iPod",
            ].includes(platform) ||
            // iPad on iOS 13 detection
            (navigator?.userAgent.includes("Mac") && "ontouchend" in document)
        );
    }

    isSafari(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    canPlayFile(extension: string): boolean {
        if (this.iOS()) return false; // allow only transcoded files on iOS for better playback

        if (extension === "mkv") {
            return false;
        }

        if (extension === "mov" && !this.isSafari()) {
            return false;
        }

        return true;
    }

    getTxServer(linkId: string) {
        console.log("Getting tx server details");
        return this.httpService
            .getTxServer(linkId)
            .toPromise()
            .then(response => {
                if (response.url) {
                    this.serverUrl = response.url;
                }
            })
            .catch(err => {
                console.log("Getting Tx Server failed", err.message);
            });
    }

    // Wait till the health check is success
    checkHealth() {
        let that = this;
        return new Promise<void>((resolve, reject) => {
            console.log("Performing health check", `${this.serverUrl}/health`);
            this.httpService
                .checkHealth(`${this.serverUrl}/health`)
                .toPromise()
                .then(response => {
                    console.log("Health check success");
                    // this.txReady.next(true)
                    this.checkingHealth = false;
                    this.healthSuccess = true;
                    resolve();
                })
                .catch(async err => {
                    this.txReady.next(false);
                    if (this.checkingHealth) {
                        // Stop health check
                        console.log(
                            "Health check failed, retrying in 5 seconds"
                        );
                        this.healthSuccess = false;
                        await this.sleep(5);
                        await this.checkHealth();
                    }
                    this.stopHealthCheckTimer();
                    this.stopTokenTimer();
                    resolve();
                });
        });
    }

    playOriginal(linkId: string) {
        this.playOriginalFile = true;
        this.init(linkId);
    }

    async init(linkId: string) {
        this.linkId = linkId;
        this.checkingHealth = true;
        this.checkIfStreamReady();

        if (this.healthSuccess || !this.streamReady.getValue()) return;

        // Get and start tx server
        await this.getTxServer(linkId);

        // Check health - for 5 mins, (every 5 seconds)
        await Promise.race([this.checkHealth(), this.sleep(5 * 60)]);
        this.checkingHealth = false;

        if (!this.healthSuccess) return;

        // Keep checking for health in the background every 10 mins, hide player if tx not reachable
        this.healthWatcher = setInterval(this.healthCheckTimer, 10 * 60 * 1000);

        this.sessionId = this.getSession();
        this.startTokenTimer();
    }

    isTxReady(): Observable<boolean> {
        return this.txReady.asObservable();
    }

    checkIfStreamReady() {
        this.linkDetails = this.dataService.getLinkDetails();

        if (this.playOriginalFile) {
            this.streamReady.next(true);
            return;
        }

        let fileName = this.linkDetails.settings?.review?.files[0]?.name;

        let extn = fileName
            ?.toLowerCase()
            ?.split(".")
            ?.pop();

        if (this.linkDetails.linkType.qikNotes) {
            if (
                !this.canPlayFile(extn) &&
                this.linkDetails.settings?.qikNotes?.files[0]
                    ?.transcodeStatus !== "SUCCESS"
            ) {
                this.streamReady.next(false);
                return;
            }
            this.checkingHealth = false;
            this.healthSuccess = true;
            this.txReady.next(true);
            this.streamReady.next(true);
        } else if (this.linkDetails.linkType.prdLibNotes) {
            this.checkingHealth = false;
            this.healthSuccess = true;
            this.txReady.next(true);
            this.streamReady.next(true);
        } else if (
            !this.canPlayFile(extn) &&
            this.linkDetails.linkType.review &&
            this.linkDetails.settings?.review?.files[0]?.transcodeStatus !==
                "SUCCESS"
        ) {
            this.streamReady.next(false);
        } else {
            this.streamReady.next(true);
        }
    }

    isStreamReady(): Observable<boolean> {
        return this.streamReady.asObservable();
    }

    getDownloadUrl(fileId): string {
        if (this.linkDetails.linkType.qikNotes) {
            return `${this.httpService.baseUrl}/api/v1/video/${this.linkId}`;
        } else {
            return `${this.serverUrl}/app/v1/drop-link/download?ref=${this.linkId}&token=${this.token}&session=${this.sessionId}&file=${fileId}`;
        }
    }

    getStreamUrl(file): any {
        let fileId = file._id;
        let hlsPath = file.hlsPath;

        let streamUrls = [];
        if (this.linkDetails.linkType.qikNotes) {
            // return `${this.httpService.baseUrl}/api/v1/video/${this.linkId}`;

            if (file.isPOC) {
                // For POC, update the test links in the hlsPath in link settings
                streamUrls.push({
                    src: `${hlsPath}`,
                    type: "application/x-mpegURL",
                });
                return streamUrls;
            }
            if (file.transcodeStatus == "SUCCESS") {
                streamUrls.push({
                    src: `${this.httpService.baseUrl}/api/v1/video/${this.linkId}/stream.m3u8`,
                    type: "application/x-mpegURL",
                });
            }

            let name = file.name && file.name.split("/").pop();
            streamUrls.push({
                src: `${this.httpService.baseUrl}/api/v1/video/${this.linkId}/${name}`,
                type: "video/mp4",
            });
        } else if (this.linkDetails.linkType.prdLibNotes) {
            streamUrls.push({
                src: `${hlsPath}`,
                type: "application/x-mpegURL",
            });
            return streamUrls;
        } else {
            if (file.hlsPath && file.transcodeStatus == "SUCCESS") {
                streamUrls.push({
                    src: `${this.serverUrl}/app/v1/drop-link/${this.linkId}/${this.token}/${this.sessionId}/${fileId}/stream/stream.m3u8`,
                    type: "application/x-mpegURL",
                });
                // streamUrls.push({
                //     src: `${this.serverUrl}/app/v1/drop-link/${this.linkId}/${this.token}/${this.sessionId}/${fileId}/stream/${hlsPath}`,
                //     type: "application/x-mpegURL",
                // });
            }

            streamUrls.push({
                src: `${this.serverUrl}/app/v1/drop-link/download?ref=${this.linkId}&token=${this.token}&session=${this.sessionId}&file=${fileId}`,
                type: "video/mp4",
            });
        }

        return streamUrls;
    }

    public generateSession(): string {
        let vals = crypto.getRandomValues(new Uint32Array(2));
        return vals[0] + "-" + vals[1];
    }

    public getSession(): string {
        let ses = localStorage.getItem("bebop#drop-link-session");
        if (!ses) {
            ses = this.generateSession();
            localStorage.setItem("bebop#drop-link-session", ses);
        }
        return ses;
    }

    async getToken(linkId: string) {
        if (Date.now() - this.tokenLastRefreshed < 10 * 60 * 1000) {
            // Token refreshed less 10 minutes ago
            return this.token;
        }

        if (Date.now() - this.tokenLastRefreshed > 15 * 60 * 1000) {
            // Token refreshed more than 15 minutes ago, token expired
            this.txReady.next(false);
        }

        try {
            let response = await this.httpService
                .getTransferToken(linkId, this.getSession())
                .toPromise();
            this.token = response.token;
            this.tokenLastRefreshed = Date.now();
            this.txReady.next(true);
        } catch (e) {
            console.log("Error getting transfer token", e);
        }

        return this.token;
    }

    healthCheckTimer() {
        this.checkingHealth = false; // Do not retry health check
        this.checkHealth();
    }

    stopHealthCheckTimer() {
        clearInterval(this.healthWatcher);
    }

    async startTokenTimer() {
        this.token = await this.getToken(this.linkId);
        this.stopTokenTimer();
        this.tokenTimer = setTimeout(this.startTokenTimer, 6 * 60 * 1000); // Refresh token every 6 mins if user active on page
    }

    stopTokenTimer() {
        clearTimeout(this.tokenTimer);
    }

    watchFocus() {
        window.onfocus = this.startTokenTimer;
        window.onblur = this.stopTokenTimer;
    }

    collapseLeftMainBlockToggler() {
        this._isLeftMainBlockCollapsed$.next(
            !this._isLeftMainBlockCollapsed$.getValue()
        );
    }
    get timeRemain() {
        return this.linkDetails.expires;
    }
}
