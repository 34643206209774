import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { timeout, catchError, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import "rxjs";

const httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
};

@Injectable({
    providedIn: "root",
})
export class HttpService {
    baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = environment.serverUrl;
    }

    getLinkDetails(id) {
        const url = `${this.baseUrl}/api/v1/get-link-details/${id}`;
        return this.http.get(url, httpOptions).pipe(
            map((response: any) => response),
            catchError((error: any) => {
                throw error;
            })
        );
    }

    validatePassword(data: { link: string; password: string }) {
        const url = `${this.baseUrl}/api/v1/validate-password`;
        return this.http.post(url, data, httpOptions).pipe(
            map((response: any) => response),
            catchError((error: any) => {
                throw error;
            })
        );
    }

    getUserDetails() {
        const url = `${this.baseUrl}/api/v1/get-user`;
        return this.http.get(url, httpOptions).pipe(
            map((response: any) => response),
            catchError((error: any) => {
                throw error;
            })
        );
    }

    logout() {
        const url = `${this.baseUrl}/api/v1/logout`;
        return this.http.post(url, {}, httpOptions).pipe(
            map((response: any) => response),
            catchError((error: any) => {
                throw error;
            })
        );
    }

    updateLinkStatus(status: string, linkId: string) {
        const url = `${this.baseUrl}/api/v1/update-status`;
        const body = {
            link: linkId,
            status,
        };
        return this.http.post(url, body, httpOptions).pipe(
            map((response: any) => response),
            catchError((error: any) => {
                throw error;
            })
        );
    }

    getTxServer(id: string) {
        const url = `${this.baseUrl}/api/v1/get-tx-server/${id}`;
        return this.http.get(url, httpOptions).pipe(
            map((response: any) => response),
            catchError((error: any) => {
                throw error;
            })
        );
    }

    checkHealth(url: string) {
        return this.http.get(url, httpOptions).pipe(
            timeout(2000),
            map((response: any) => response),
            catchError((error: any) => {
                throw error;
            })
        );
    }

    getTransferToken(id: string, sessionId: string) {
        const url = `${this.baseUrl}/api/v1/get-transfer-token/${id}/${sessionId}`;
        return this.http.get(url, httpOptions).pipe(
            map((response: any) => response),
            catchError((error: any) => {
                throw error;
            })
        );
    }
}
