import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
    HostBinding,
    ViewChild,
    Inject,
} from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { ActivatedRoute, Router } from "@angular/router";
import { CustomNotificationsService } from "src/app/shared/custom-notifications/custom-notifications.service";

import { HttpService } from "src/app/services/http.service";
import { CustomDialogService } from "src/app/shared/custom-dialog/custom-dialog.service";
import { AuthService, IUser } from "src/app/services/auth.service";
import { LSHandler } from "src/app/utils/localStorageHandler";
import { LeaveCommentComponent } from "src/app/shared/dialogs/leave-comment/leave-comment.component";
import { Subject } from "rxjs";

@Component({
    selector: "app-toolbar-user",
    templateUrl: "./toolbar-user.component.html",
    styleUrls: ["./toolbar-user.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ToolbarUserComponent implements OnInit {
    @HostBinding("class")
    elementClass = "full-height";
    @Input() user: any;
    @ViewChild("notification", { static: false }) notification;

    private auth: string;
    private unsubscribe$: Subject<any> = new Subject();
    constructor(
        private notificationService: CustomNotificationsService,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpService,
        private dialogService: CustomDialogService,
        private authService: AuthService
    ) {
        this.authService.state$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(data => {
                this.auth = data.auth;
            });
    }

    public isUserOpen: boolean = false;
    /**
     * Determine if current screen is matches provided screen width.
     * @param {number} breakPoint - number of pixels for the "max-width" media query.
     * @returns {boolean} true if current screen is matches media query.
     */
    public isMobile = (breakPoint = 428): boolean => {
        return window.matchMedia(`screen and (max-width: ${breakPoint}px)`)
            .matches;
    };

    toggleDropdown() {
        this.isUserOpen = !this.isUserOpen;
    }
    onClickOutside() {
        this.isUserOpen = false;
    }

    updateDisplayName() {
        const ref = this.dialogService.open(LeaveCommentComponent, {
            data: { isGuest: true, updateDetails: true },
        });
        ref.afterClosed.subscribe(filledData => {
            if (filledData) {
                this.user = LSHandler("userData", filledData);
                this.authService.setUser(this.user, this.auth);
            }
        });
    }

    onLogin() {
        window.open(
            `api/v1/login/${this.route.snapshot.parent.params.link}`,
            "_self"
        );
    }

    onLogout() {
        this.http.logout().subscribe(() => {
            this.router.navigate([
                this.route.snapshot.parent.params.link,
                "login",
            ]);
        });
    }

    ngOnInit() {}
}
