<div
    class="toolbar"
    [ngClass]="{
        'toolbar-mobile': isMobile()
    }"
>
    <div class="toolbar-logo">
        <img [src]="dataService.getAppLogo()" alt="" class="left-logo" />
        <div *ngIf="dataService.hasCustomLogo" class="powered-by">
            <img src="assets/images/powered-by-bebop.png" alt="" />
        </div>
    </div>
    <ng-container *ngIf="userData && userData.name">
        <div class="toolbar-user-container">
            <app-toolbar-user [user]="userData"></app-toolbar-user>
        </div>
    </ng-container>
    <ng-container *ngIf="!userData || !userData.name">
        <div class="toolbar-login">
            <button
                class="custom-button toolbar-login-login-button"
                (click)="onLoginClick()"
            >
                Log in
            </button>
            <button
                class="custom-button custom-button-filled toolbar-login-register-button"
                (click)="onRegisterClick()"
                *ngIf="false"
            >
                Register
            </button>
        </div>
    </ng-container>
</div>
