import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";

@Component({
    selector: "app-layout-protected-links",
    templateUrl: "./layout-protected-links.component.html",
    styleUrls: ["./layout-protected-links.component.scss"],
})
export class LayoutProtectedLinksComponent implements OnInit {
    constructor(public dataService: DataService) {}

    ngOnInit(): void {}
}
