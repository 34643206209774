import { Observable, Subject } from "rxjs";

export class CustomDialogRef {
    constructor() {}

    close(result?: any) {
        this._afterClosed.next(result);
    }

    private readonly _afterClosed = new Subject<any>();
    public afterClosed: Observable<any> = this._afterClosed.asObservable();
}
