import {
    trigger,
    animate,
    transition,
    style,
    query,
} from "@angular/animations";

export const fadeAnimationVoid = trigger("fadeAnimationVoid", [
    transition("void => *", [style({ opacity: 0 }), animate(300)]),
    transition("* => void", [style({ opacity: 1 }), animate(0)]),
]);

export const fadeAnimation = trigger("fadeAnimation", [
    // The '* => *' will trigger the animation to change between any two states
    transition("* => *", [
        query(":enter", [style({ opacity: 0 })], { optional: true }),
        query(
            ":leave",
            // here we apply a style and use the animate function to apply the style over 0.3 seconds
            [style({ opacity: 1 }), animate("0.2s", style({ opacity: 0 }))],
            { optional: true }
        ),
        query(
            ":enter",
            [style({ opacity: 0 }), animate("0.2s", style({ opacity: 1 }))],
            {
                optional: true,
            }
        ),
    ]),
]);
