import { Component, OnInit, OnDestroy, Optional, Input } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CustomDialogRef } from "../../custom-dialog/custom-dialog-ref";
import { CustomDialogConfig } from "../../custom-dialog/custom-dialog-config";
import { Subject } from "rxjs";

@Component({
    selector: "app-leave-comment",
    templateUrl: "./leave-comment.component.html",
    styleUrls: ["./leave-comment.component.scss"],
})
export class LeaveCommentComponent implements OnInit, OnDestroy {
    @Input() isGuest;
    @Input() updateDetails;
    private unsubscribe$: Subject<any> = new Subject();

    constructor(
        public dialog: CustomDialogRef,
        @Optional() private config?: CustomDialogConfig
    ) {}
    public form: FormGroup;
    /**
     * Determine if current screen is matches provided screen width.
     * @param {number} breakPoint - number of pixels for the "max-width" media query.
     * @returns {boolean} true if current screen is matches media query.
     */
    public isMobile = (breakPoint = 528): boolean => {
        return window.matchMedia(`screen and (max-width: ${breakPoint}px)`)
            .matches;
    };

    getErrorMessage(control) {
        if (control.errors.required) {
            return "Field is required";
        } else if (control.errors.minlength) {
            return "Min length is 5 characters";
        }
    }

    onClose(e?, create?) {
        if (
            !e ||
            e.key === " " ||
            e.key === "Enter" ||
            e instanceof MouseEvent
        ) {
            if (create) {
                return this.dialog.close(this.form.value);
            } else {
                return this.dialog.close();
            }
        }
    }

    ngOnInit(): void {
        if (this.config) {
            this.isGuest = this.config.data.isGuest;
            this.updateDetails = this.config.data.updateDetails;
        }
        if (this.isGuest) {
            this.form = new FormGroup({
                name: new FormControl("", [Validators.required]),
                email: new FormControl("", [
                    Validators.email,
                    Validators.required,
                ]),
            });
        } else {
            this.form = new FormGroup({
                name: new FormControl("", [Validators.required]),
                email: new FormControl("", [Validators.required]),
                password: new FormControl("", [
                    Validators.required,
                    Validators.minLength(5),
                ]),
            });
        }
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
