import { Injectable } from "@angular/core";
import {
    CanActivateChild,
    Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "src/app/services/auth.service";

import { HttpService } from "src/app/services/http.service";
import { VideoService } from "../pages/library/video/video.service";
import { DataService } from "src/app/services/data.service";
@Injectable({
    providedIn: "root",
})
export class ValidIdService implements CanActivateChild {
    constructor(
        private httpService: HttpService,
        private router: Router,
        private authService: AuthService,
        private dataService: DataService,
        private videoService: VideoService
    ) {}
    public authorized: boolean;
    public link: string;

    private _link$: BehaviorSubject<string> = new BehaviorSubject<string>("");

    public get link$() {
        return this._link$.asObservable();
    }
    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const link = childRoute.params.link;
            this.link = link;
            this._link$.next(this.link);
            const dropLinkRes = this.httpService
                .getLinkDetails(link)
                .toPromise();

            dropLinkRes
                .then(data => {
                    this.dataService.setLinkDetails(data);
                    this.authorized = data?.authorized;
                    this.videoService.set(data, false);

                    if (data.logos && data.logos.HORIZONTAL_LIGHT) {
                        this.dataService.setAppLogo(
                            data.logos.HORIZONTAL_LIGHT
                        );
                    }

                    if (data.valid) {
                        if (
                            !(
                                data.linkType.review ||
                                data.linkType.qikNotes ||
                                data.linkType.prdLibNotes
                            )
                        ) {
                            this.router.navigateByUrl(`/${link}/error`);
                            return resolve(false);
                        }

                        if (data.authorized) {
                            this.authService.getUser();
                            return resolve(true);
                        } else if (data.auth === "USER" && !data.authorized) {
                            this.router.navigateByUrl(`/${link}/login`);
                            return resolve(false);
                        } else if (
                            data.auth === "PASSWORD" &&
                            !data.authorized
                        ) {
                            this.router.navigateByUrl(`/${link}/password`);
                            return resolve(false);
                        } else {
                            this.router.navigateByUrl(`/${link}`);
                            return resolve(false);
                        }
                    } else {
                        this.router.navigateByUrl(`/${link}/error`, {
                            state: { reason: data.reason },
                        });
                        return resolve(false);
                    }
                })
                .catch(error => {
                    console.error("Error:", error.error.error?.details);
                    if (error.error.error?.details) {
                        this.router.navigateByUrl(`/${link}/error`, {
                            state: { reason: error.error.error.details },
                        });
                    }
                });
        });
    }
}
