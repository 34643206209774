import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CustomDialogComponent } from "./custom-dialog.component";
import { CustomDialogDirective } from "./custom-dialog.directive";
import { CustomDialogRef } from "./custom-dialog-ref";

@NgModule({
    imports: [CommonModule],
    declarations: [CustomDialogComponent, CustomDialogDirective],
    exports: [CustomDialogComponent],
    providers: [{ provide: CustomDialogRef, useClass: CustomDialogRef }],
})
export class CustomDialogModule {}
