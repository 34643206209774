import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToolbarUserComponent } from "./toolbar-user.component";
import { ClickOutsideModule } from "../../click-outside/click-outside.module";
import { CustomDialogModule } from "src/app/shared/custom-dialog/custom-dialog.module";

@NgModule({
    declarations: [ToolbarUserComponent],
    imports: [CommonModule, ClickOutsideModule, CustomDialogModule],
    exports: [ToolbarUserComponent],
})
export class ToolbarUserModule {}
