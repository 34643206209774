import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CustomNotificationsComponent } from "./custom-notifications.component";
import { CustomNotificationComponent } from "./custom-notification/custom-notification.component";

@NgModule({
    declarations: [CustomNotificationsComponent, CustomNotificationComponent],
    imports: [CommonModule],
    exports: [CustomNotificationsComponent],
})
export class CustomNotificationsModule {}
