import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LayoutComponent } from "./layout.component";
import { RouterModule } from "@angular/router";
import { ToolbarModule } from "../toolbar/toolbar.module";
import { CustomNotificationsModule } from "src/app/shared/custom-notifications/custom-notifications.module";
import { LayoutProtectedLinksComponent } from "./layout-protected-links/layout-protected-links.component";

@NgModule({
    declarations: [LayoutComponent, LayoutProtectedLinksComponent],
    imports: [
        CommonModule,
        RouterModule,
        ToolbarModule,
        CustomNotificationsModule,
    ],
    exports: [LayoutComponent],
})
export class LayoutModule {}
