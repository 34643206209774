import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ToolbarComponent } from "./toolbar.component";
import { ToolbarUserModule } from "./toolbar-user/toolbar-user.module";

@NgModule({
    declarations: [ToolbarComponent],
    imports: [CommonModule, ToolbarUserModule],
    exports: [ToolbarComponent],
})
export class ToolbarModule {}
